import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Agent } from '../models/agent';
import { QueryParams } from '../models/query-params';
import { User } from '../models/user';
import { ApiService } from './api.service';
import { IdAccountService } from './id-account.service';
import Utils from '../components/shared/utils';
import { PaginatedParams } from '../models/paginated-params';
import { PaginatedAgents } from '../models/paginated-agents';

@Injectable({
    providedIn: 'root',
})
export class AgentService extends ApiService {
    private id: number;
    private baseUrl: string;

    constructor(
        private http: HttpClient,
        private IdAccountService: IdAccountService,
        protected dialogService: DialogService
    ) {
        super(http, dialogService);
        this.id = this.IdAccountService.getIdAccountService();
        this.baseUrl = 'api/thot/accounts/' + this.id + '/chronos';
    }
    // ! Uses .pipe => Not compatible with current apiService, Find a solution for this
    public getAgents(filters?: any): Observable<Agent[]> {
        let params: HttpParams = new HttpParams();
        const flexFields: { [key: string]: any } = {
            fields: [
                'id',
                'user.id',
                'user.last_name',
                'user.first_name',
                'user.groups',
                'user.permissions',
                'user.email',
                'user.is_active',
                'code',
                'group',
                'warehouse_hq.designation',
                'related_assigned_account_providers.id',
                'related_assigned_account_providers.designation',
            ],
            expand: [
                'user',
                'user.groups',
                'user.permissions',
                'user.email',
                'warehouse_hq',
                'related_assigned_account_providers',
            ],
        };
        const queryParams = `?${new URLSearchParams(flexFields).toString()}`;
        if (filters) {
            Object.keys(filters).forEach((key) => {
                if (Utils.isNotNil(filters[key])) {
                    params = params.append(key, filters[key]);
                }
            });
        }
        return this.http
            .get<Agent[]>(
                `${environment.apiHost}/api/thot/accounts/${this.id}/chronos/agents/${queryParams}`,
                { params: params }
            )
            .pipe(
                map((agents) => {
                    return agents.map((agent) => {
                        agent[
                            'label'
                        ] = `${agent.code} - ${agent.user.last_name} ${agent.user.first_name}`;
                        agent.user = new User(agent.user);
                        return agent;
                    });
                })
            );
    }

    public getAgent(id: number, queryParams?: QueryParams): Promise<Agent> {
        return this.getDetailRequest(
            `${this.baseUrl}/agents/${id}`,
            queryParams
        );
    }

    // ! Uses .pipe => Not compatible with current apiService, Find a solution for this
    public countAgentBy(filters: {}): Observable<{ [key: string]: any }> {
        const queryParams = { filters: JSON.stringify(filters) };
        return this.http.get<{ [key: string]: any }>(
            `${environment.apiHost}/${this.baseUrl
            }/agents/find_by/?${new URLSearchParams(queryParams).toString()}`
        );
    }

    public createAgent(agent: Agent): Promise<Agent> {
        agent.account_id = this.id;
        return this.postRequest(`${this.baseUrl}/agents`, agent);
    }

    public updateAgent(
        agent: Agent,
        data: { [key: string]: any }
    ): Promise<Agent> {
        const queryParams: QueryParams = {
            fields: 'id,user.last_name,user.first_name',
            expand: 'warehouse_hq,user,optionnal_vehicle',
        };
        return this.patchRequest(
            `${this.baseUrl}/agents/${agent.id}`,
            data,
            queryParams
        );
    }

    public passwordReset(agent: Agent, data: { [key: string]: any }) {
        return this.patchRequest(`${this.baseUrl}/agents/${agent.id}/password_reset`,
            data
        );
    }

    public getPaginatedAgentAccounts(
        queryParams: QueryParams,
        paginatedParams: PaginatedParams
    ): Promise<PaginatedAgents> {
        return this.paginatedRequest(
            `${this.baseUrl}/agents`,
            queryParams,
            paginatedParams
        );
    }
}
