/* eslint-disable @angular-eslint/no-output-on-prefix */
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { CountriesService } from 'src/app/services/countries.service';

@Component({
    standalone: true,
    selector: 'app-country-list',
    imports: [DropdownModule, FormsModule, CommonModule],
    templateUrl: './country-list.component.html',
    styleUrls: ['./country-list.component.scss'],
})
export class CountryListComponent implements OnInit {
    @Input() selectedCountry: any;
    @Input() countryToSelect: string; // country code to select
    @Input() disabled: boolean = false;
    @Output() onChanged = new EventEmitter();

    countries = [
        {
            "name": {
                "common": "Ivory Coast",
                "official": "Republic of Côte d'Ivoire",
                "nativeName": {
                    "fra": {
                        "official": "République de Côte d'Ivoire",
                        "common": "Côte d'Ivoire"
                    }
                }
            },
            "tld": [
                ".ci"
            ],
            "cca2": "CI",
            "ccn3": "384",
            "cca3": "CIV",
            "cioc": "CIV",
            "independent": true,
            "status": "officially-assigned",
            "unMember": true,
            "currencies": {
                "XOF": {
                    "name": "West African CFA franc",
                    "symbol": "Fr"
                }
            },
            "idd": {
                "root": "+2",
                "suffixes": [
                    "25"
                ]
            },
            "capital": [
                "Yamoussoukro"
            ],
            "altSpellings": [
                "CI",
                "Côte d'Ivoire",
                "Ivory Coast",
                "Republic of Côte d'Ivoire",
                "République de Côte d'Ivoire"
            ],
            "region": "Africa",
            "subregion": "Western Africa",
            "languages": {
                "fra": "French"
            },
            "translations": {
                "ara": {
                    "official": "جمهورية ساحل العاج",
                    "common": "ساحل العاج"
                },
                "bre": {
                    "official": "Republik Aod an Olifant",
                    "common": "Aod an Olifant"
                },
                "ces": {
                    "official": "Republika Pobřeží slonoviny",
                    "common": "Pobřeží slonoviny"
                },
                "cym": {
                    "official": "Republic of Côte d'Ivoire",
                    "common": "Ivory Coast"
                },
                "deu": {
                    "official": "Republik Côte d'Ivoire",
                    "common": "Elfenbeinküste"
                },
                "est": {
                    "official": "Côte d’Ivoire’i Vabariik",
                    "common": "Elevandiluurannik"
                },
                "fin": {
                    "official": "Norsunluurannikon tasavalta",
                    "common": "Norsunluurannikko"
                },
                "fra": {
                    "official": "République de Côte d' Ivoire",
                    "common": "Côte d'Ivoire"
                },
                "hrv": {
                    "official": "Republika Côte d'Ivoire",
                    "common": "Obala Bjelokosti"
                },
                "hun": {
                    "official": "Elefántcsontparti Köztársaság",
                    "common": "Elefántcsontpart"
                },
                "ita": {
                    "official": "Repubblica della Costa d'Avorio",
                    "common": "Costa d'Avorio"
                },
                "jpn": {
                    "official": "コートジボワール共和国",
                    "common": "コートジボワール"
                },
                "kor": {
                    "official": "코트디부아르 공화국",
                    "common": "코트디부아르"
                },
                "nld": {
                    "official": "Republiek Ivoorkust",
                    "common": "Ivoorkust"
                },
                "per": {
                    "official": "جمهوری ساحل عاج",
                    "common": "ساحل عاج"
                },
                "pol": {
                    "official": "Republika WybrzeŻa Kości Słoniowej",
                    "common": "WybrzeŻe Kości Słoniowej"
                },
                "por": {
                    "official": "República da Côte d'Ivoire",
                    "common": "Costa do Marfim"
                },
                "rus": {
                    "official": "Республика Кот-д'Ивуаре",
                    "common": "Кот-д’Ивуар"
                },
                "slk": {
                    "official": "Republika Pobrežie Slonoviny",
                    "common": "Pobržie Slonoviny"
                },
                "spa": {
                    "official": "República de Côte d'Ivoire",
                    "common": "Costa de Marfil"
                },
                "srp": {
                    "official": "Република Обала Слоноваче",
                    "common": "Обала Слоноваче"
                },
                "swe": {
                    "official": "Republiken Elfenbenskusten",
                    "common": "Elfenbenskusten"
                },
                "tur": {
                    "official": "Fildişi Sahili",
                    "common": "Fildişi Sahili"
                },
                "urd": {
                    "official": "جمہوریہ کوت دیواغ",
                    "common": "آئیوری کوسٹ"
                },
                "zho": {
                    "official": "科特迪瓦共和国",
                    "common": "科特迪瓦"
                }
            },
            "latlng": [
                8.0,
                -5.0
            ],
            "landlocked": false,
            "borders": [
                "BFA",
                "GHA",
                "GIN",
                "LBR",
                "MLI"
            ],
            "area": 322463.0,
            "demonyms": {
                "eng": {
                    "f": "Ivorian",
                    "m": "Ivorian"
                },
                "fra": {
                    "f": "Ivoirienne",
                    "m": "Ivoirien"
                }
            },
            "flag": "🇨🇮",
            "maps": {
                "googleMaps": "https://goo.gl/maps/wKsmN7f5qAeNtGjP6",
                "openStreetMaps": "https://www.openstreetmap.org/relation/192779"
            },
            "population": 26378275,
            "gini": {
                "2015": 41.5
            },
            "fifa": "CIV",
            "car": {
                "signs": [
                    "CI"
                ],
                "side": "right"
            },
            "timezones": [
                "UTC"
            ],
            "continents": [
                "Africa"
            ],
            "flags": {
                "png": "https://flagcdn.com/w320/ci.png",
                "svg": "https://flagcdn.com/ci.svg",
                "alt": "The flag of Ivory Coast is composed of three equal vertical bands of orange, white and green."
            },
            "coatOfArms": {
                "png": "https://mainfacts.com/media/images/coats_of_arms/ci.png",
                "svg": "https://mainfacts.com/media/images/coats_of_arms/ci.svg"
            },
            "startOfWeek": "monday",
            "capitalInfo": {
                "latlng": [
                    6.82,
                    -5.27
                ]
            }
        },
        {
            "name": {
                "common": "Martinique",
                "official": "Martinique",
                "nativeName": {
                    "fra": {
                        "official": "Martinique",
                        "common": "Martinique"
                    }
                }
            },
            "tld": [
                ".mq"
            ],
            "cca2": "MQ",
            "ccn3": "474",
            "cca3": "MTQ",
            "independent": false,
            "status": "officially-assigned",
            "unMember": false,
            "currencies": {
                "EUR": {
                    "name": "Euro",
                    "symbol": "€"
                }
            },
            "idd": {
                "root": "+5",
                "suffixes": [
                    "96"
                ]
            },
            "capital": [
                "Fort-de-France"
            ],
            "altSpellings": [
                "MQ"
            ],
            "region": "Americas",
            "subregion": "Caribbean",
            "languages": {
                "fra": "French"
            },
            "translations": {
                "ara": {
                    "official": "مارتينيك",
                    "common": "مارتينيك"
                },
                "bre": {
                    "official": "Martinik",
                    "common": "Martinik"
                },
                "ces": {
                    "official": "Martinik",
                    "common": "Martinik"
                },
                "cym": {
                    "official": "Martinique",
                    "common": "Martinique"
                },
                "deu": {
                    "official": "Martinique",
                    "common": "Martinique"
                },
                "est": {
                    "official": "Martinique’i departemang",
                    "common": "Martinique"
                },
                "fin": {
                    "official": "Martinique",
                    "common": "Martinique"
                },
                "fra": {
                    "official": "Martinique",
                    "common": "Martinique"
                },
                "hrv": {
                    "official": "Martinique",
                    "common": "Martinique"
                },
                "hun": {
                    "official": "Martinique",
                    "common": "Martinique"
                },
                "ita": {
                    "official": "Martinique",
                    "common": "Martinica"
                },
                "jpn": {
                    "official": "マルティニーク島",
                    "common": "マルティニーク"
                },
                "kor": {
                    "official": "마르티니크",
                    "common": "마르티니크"
                },
                "nld": {
                    "official": "Martinique",
                    "common": "Martinique"
                },
                "per": {
                    "official": "مارتینیک",
                    "common": "مارتینیک"
                },
                "pol": {
                    "official": "Martynika",
                    "common": "Martynika"
                },
                "por": {
                    "official": "Martinique",
                    "common": "Martinica"
                },
                "rus": {
                    "official": "Мартиника",
                    "common": "Мартиника"
                },
                "slk": {
                    "official": "Martinique",
                    "common": "Martinique"
                },
                "spa": {
                    "official": "Martinica",
                    "common": "Martinica"
                },
                "srp": {
                    "official": "Мартиник",
                    "common": "Мартиник"
                },
                "swe": {
                    "official": "Martinique",
                    "common": "Martinique"
                },
                "tur": {
                    "official": "Martinik",
                    "common": "Martinik"
                },
                "urd": {
                    "official": "مارٹینیک",
                    "common": "مارٹینیک"
                },
                "zho": {
                    "official": "马提尼克",
                    "common": "马提尼克"
                }
            },
            "latlng": [
                14.666667,
                -61.0
            ],
            "landlocked": false,
            "area": 1128.0,
            "demonyms": {
                "eng": {
                    "f": "Martinican",
                    "m": "Martinican"
                },
                "fra": {
                    "f": "Martiniquaise",
                    "m": "Martiniquais"
                }
            },
            "flag": "🇲🇶",
            "maps": {
                "googleMaps": "https://goo.gl/maps/87ER7sDAFU7JjcvR6",
                "openStreetMaps": "https://www.openstreetmap.org/relation/2473088"
            },
            "population": 378243,
            "car": {
                "signs": [
                    "F"
                ],
                "side": "right"
            },
            "timezones": [
                "UTC-04:00"
            ],
            "continents": [
                "North America"
            ],
            "flags": {
                "png": "https://flagcdn.com/w320/mq.png",
                "svg": "https://flagcdn.com/mq.svg"
            },
            "coatOfArms": {
                "png": "https://mainfacts.com/media/images/coats_of_arms/mq.png",
                "svg": "https://mainfacts.com/media/images/coats_of_arms/mq.svg"
            },
            "startOfWeek": "monday",
            "capitalInfo": {
                "latlng": [
                    14.6,
                    -61.08
                ]
            },
            "postalCode": {
                "format": "#####",
                "regex": "^(\\d{5})$"
            }
        },
        {
            "name": {
                "common": "Luxembourg",
                "official": "Grand Duchy of Luxembourg",
                "nativeName": {
                    "deu": {
                        "official": "Großherzogtum Luxemburg",
                        "common": "Luxemburg"
                    },
                    "fra": {
                        "official": "Grand-Duché de Luxembourg",
                        "common": "Luxembourg"
                    },
                    "ltz": {
                        "official": "Groussherzogtum Lëtzebuerg",
                        "common": "Lëtzebuerg"
                    }
                }
            },
            "tld": [
                ".lu"
            ],
            "cca2": "LU",
            "ccn3": "442",
            "cca3": "LUX",
            "cioc": "LUX",
            "independent": true,
            "status": "officially-assigned",
            "unMember": true,
            "currencies": {
                "EUR": {
                    "name": "Euro",
                    "symbol": "€"
                }
            },
            "idd": {
                "root": "+3",
                "suffixes": [
                    "52"
                ]
            },
            "capital": [
                "Luxembourg"
            ],
            "altSpellings": [
                "LU",
                "Grand Duchy of Luxembourg",
                "Grand-Duché de Luxembourg",
                "Großherzogtum Luxemburg",
                "Groussherzogtum Lëtzebuerg"
            ],
            "region": "Europe",
            "subregion": "Western Europe",
            "languages": {
                "deu": "German",
                "fra": "French",
                "ltz": "Luxembourgish"
            },
            "translations": {
                "ara": {
                    "official": "دوقية لوكسمبورغ",
                    "common": "لوكسمبورغ"
                },
                "bre": {
                    "official": "Dugelezh Veur Luksembourg",
                    "common": "Luksembourg"
                },
                "ces": {
                    "official": "Lucemburské velkovévodství",
                    "common": "Lucembursko"
                },
                "cym": {
                    "official": "Grand Duchy of Luxembourg",
                    "common": "Luxembourg"
                },
                "deu": {
                    "official": "Großherzogtum Luxemburg,",
                    "common": "Luxemburg"
                },
                "est": {
                    "official": "Luksemburgi Suurhertsogiriik",
                    "common": "Luksemburg"
                },
                "fin": {
                    "official": "Luxemburgin suurherttuakunta",
                    "common": "Luxemburg"
                },
                "fra": {
                    "official": "Grand-Duché de Luxembourg",
                    "common": "Luxembourg"
                },
                "hrv": {
                    "official": "Veliko Vojvodstvo Luksemburg",
                    "common": "Luksemburg"
                },
                "hun": {
                    "official": "Luxemburgi Nagyhercegség",
                    "common": "Luxemburg"
                },
                "ita": {
                    "official": "Granducato di Lussemburgo",
                    "common": "Lussemburgo"
                },
                "jpn": {
                    "official": "ルクセンブルク大公国",
                    "common": "ルクセンブルク"
                },
                "kor": {
                    "official": "룩셈부르크 대공국",
                    "common": "룩셈부르크"
                },
                "nld": {
                    "official": "Groothertogdom Luxemburg",
                    "common": "Luxemburg"
                },
                "per": {
                    "official": "دوک‌نشین لوکزامبورگ",
                    "common": "لوکزامبورگ"
                },
                "pol": {
                    "official": "Wielkie Księstwo Luksemburga",
                    "common": "Luksemburg"
                },
                "por": {
                    "official": "Grão-Ducado do Luxemburgo",
                    "common": "Luxemburgo"
                },
                "rus": {
                    "official": "Великое Герцогство Люксембург",
                    "common": "Люксембург"
                },
                "slk": {
                    "official": "Luxemburské veľkovojvodstvo",
                    "common": "Luxembursko"
                },
                "spa": {
                    "official": "Gran Ducado de Luxemburgo",
                    "common": "Luxemburgo"
                },
                "srp": {
                    "official": "Велико Војводство Луксембург",
                    "common": "Луксембург"
                },
                "swe": {
                    "official": "Storhertigdömet Luxemburg",
                    "common": "Luxemburg"
                },
                "tur": {
                    "official": "Lüksemburg Büyük Dükalığı",
                    "common": "Lüksemburg"
                },
                "urd": {
                    "official": "دوقیہ کبیرلکسمبرگ",
                    "common": "لکسمبرگ"
                },
                "zho": {
                    "official": "卢森堡大公国",
                    "common": "卢森堡"
                }
            },
            "latlng": [
                49.75,
                6.16666666
            ],
            "landlocked": true,
            "borders": [
                "BEL",
                "FRA",
                "DEU"
            ],
            "area": 2586.0,
            "demonyms": {
                "eng": {
                    "f": "Luxembourger",
                    "m": "Luxembourger"
                },
                "fra": {
                    "f": "Luxembourgeoise",
                    "m": "Luxembourgeois"
                }
            },
            "flag": "🇱🇺",
            "maps": {
                "googleMaps": "https://goo.gl/maps/L6b2AgndgHprt2Ko9",
                "openStreetMaps": "https://www.openstreetmap.org/relation/2171347#map=10/49.8167/6.1335"
            },
            "population": 632275,
            "gini": {
                "2018": 35.4
            },
            "fifa": "LUX",
            "car": {
                "signs": [
                    "L"
                ],
                "side": "right"
            },
            "timezones": [
                "UTC+01:00"
            ],
            "continents": [
                "Europe"
            ],
            "flags": {
                "png": "https://flagcdn.com/w320/lu.png",
                "svg": "https://flagcdn.com/lu.svg",
                "alt": "The flag of Luxembourg is composed of three equal horizontal bands of red, white and light blue."
            },
            "coatOfArms": {
                "png": "https://mainfacts.com/media/images/coats_of_arms/lu.png",
                "svg": "https://mainfacts.com/media/images/coats_of_arms/lu.svg"
            },
            "startOfWeek": "monday",
            "capitalInfo": {
                "latlng": [
                    49.6,
                    6.12
                ]
            },
            "postalCode": {
                "format": "####",
                "regex": "^(\\d{4})$"
            }
        },
        {
            "name": {
                "common": "Guadeloupe",
                "official": "Guadeloupe",
                "nativeName": {
                    "fra": {
                        "official": "Guadeloupe",
                        "common": "Guadeloupe"
                    }
                }
            },
            "tld": [
                ".gp"
            ],
            "cca2": "GP",
            "ccn3": "312",
            "cca3": "GLP",
            "independent": false,
            "status": "officially-assigned",
            "unMember": false,
            "currencies": {
                "EUR": {
                    "name": "Euro",
                    "symbol": "€"
                }
            },
            "idd": {
                "root": "+5",
                "suffixes": [
                    "90"
                ]
            },
            "capital": [
                "Basse-Terre"
            ],
            "altSpellings": [
                "GP",
                "Gwadloup"
            ],
            "region": "Americas",
            "subregion": "Caribbean",
            "languages": {
                "fra": "French"
            },
            "translations": {
                "ara": {
                    "official": "غوادلوب",
                    "common": "غوادلوب"
                },
                "bre": {
                    "official": "Gwadeloup",
                    "common": "Gwadeloup"
                },
                "ces": {
                    "official": "Guadeloupe",
                    "common": "Guadeloupe"
                },
                "cym": {
                    "official": "Guadeloupe",
                    "common": "Guadeloupe"
                },
                "deu": {
                    "official": "Guadeloupe",
                    "common": "Guadeloupe"
                },
                "est": {
                    "official": "Guadeloupe’i ja sõltkondade departemang",
                    "common": "Guadeloupe"
                },
                "fin": {
                    "official": "Guadeloupen departmentti",
                    "common": "Guadeloupe"
                },
                "fra": {
                    "official": "Guadeloupe",
                    "common": "Guadeloupe"
                },
                "hrv": {
                    "official": "Gvadalupa",
                    "common": "Gvadalupa"
                },
                "hun": {
                    "official": "Guadeloupe",
                    "common": "Guadeloupe"
                },
                "ita": {
                    "official": "Guadeloupe",
                    "common": "Guadeloupa"
                },
                "jpn": {
                    "official": "グアドループ島",
                    "common": "グアドループ"
                },
                "kor": {
                    "official": "과들루프",
                    "common": "과들루프"
                },
                "nld": {
                    "official": "Guadeloupe",
                    "common": "Guadeloupe"
                },
                "per": {
                    "official": "گوادلوپ",
                    "common": "گوادلوپ"
                },
                "pol": {
                    "official": "Gwadelupa",
                    "common": "Gwadelupa"
                },
                "por": {
                    "official": "Guadalupe",
                    "common": "Guadalupe"
                },
                "rus": {
                    "official": "Гваделупа",
                    "common": "Гваделупа"
                },
                "slk": {
                    "official": "Guadeloupe",
                    "common": "Guadeloupe"
                },
                "spa": {
                    "official": "Guadalupe",
                    "common": "Guadalupe"
                },
                "srp": {
                    "official": "Гваделуп",
                    "common": "Гваделуп"
                },
                "swe": {
                    "official": "Guadeloupe",
                    "common": "Guadeloupe"
                },
                "tur": {
                    "official": "Guadeloupe",
                    "common": "Guadeloupe"
                },
                "urd": {
                    "official": "گواڈیلوپ",
                    "common": "گواڈیلوپ"
                },
                "zho": {
                    "official": "瓜德罗普岛",
                    "common": "瓜德罗普岛"
                }
            },
            "latlng": [
                16.25,
                -61.583333
            ],
            "landlocked": false,
            "area": 1628.0,
            "demonyms": {
                "eng": {
                    "f": "Guadeloupian",
                    "m": "Guadeloupian"
                },
                "fra": {
                    "f": "Guadeloupéenne",
                    "m": "Guadeloupéen"
                }
            },
            "flag": "🇬🇵",
            "maps": {
                "googleMaps": "https://goo.gl/maps/Dy9R2EufJtoWm8UN9",
                "openStreetMaps": "https://www.openstreetmap.org/relation/7109289"
            },
            "population": 400132,
            "car": {
                "signs": [
                    "F"
                ],
                "side": "right"
            },
            "timezones": [
                "UTC-04:00"
            ],
            "continents": [
                "North America"
            ],
            "flags": {
                "png": "https://flagcdn.com/w320/gp.png",
                "svg": "https://flagcdn.com/gp.svg"
            },
            "coatOfArms": {
                "png": "https://mainfacts.com/media/images/coats_of_arms/gp.png",
                "svg": "https://mainfacts.com/media/images/coats_of_arms/gp.svg"
            },
            "startOfWeek": "monday",
            "capitalInfo": {
                "latlng": [
                    16.03,
                    -61.73
                ]
            },
            "postalCode": {
                "format": "#####",
                "regex": "^((97|98)\\d{3})$"
            }
        },
        {
            "name": {
                "common": "France",
                "official": "French Republic",
                "nativeName": {
                    "fra": {
                        "official": "République française",
                        "common": "France"
                    }
                }
            },
            "tld": [
                ".fr"
            ],
            "cca2": "FR",
            "ccn3": "250",
            "cca3": "FRA",
            "cioc": "FRA",
            "independent": true,
            "status": "officially-assigned",
            "unMember": true,
            "currencies": {
                "EUR": {
                    "name": "Euro",
                    "symbol": "€"
                }
            },
            "idd": {
                "root": "+3",
                "suffixes": [
                    "3"
                ]
            },
            "capital": [
                "Paris"
            ],
            "altSpellings": [
                "FR",
                "French Republic",
                "République française"
            ],
            "region": "Europe",
            "subregion": "Western Europe",
            "languages": {
                "fra": "French"
            },
            "translations": {
                "ara": {
                    "official": "الجمهورية الفرنسية",
                    "common": "فرنسا"
                },
                "bre": {
                    "official": "Republik Frañs",
                    "common": "Frañs"
                },
                "ces": {
                    "official": "Francouzská republika",
                    "common": "Francie"
                },
                "cym": {
                    "official": "French Republic",
                    "common": "France"
                },
                "deu": {
                    "official": "Französische Republik",
                    "common": "Frankreich"
                },
                "est": {
                    "official": "Prantsuse Vabariik",
                    "common": "Prantsusmaa"
                },
                "fin": {
                    "official": "Ranskan tasavalta",
                    "common": "Ranska"
                },
                "fra": {
                    "official": "République française",
                    "common": "France"
                },
                "hrv": {
                    "official": "Francuska Republika",
                    "common": "Francuska"
                },
                "hun": {
                    "official": "Francia Köztársaság",
                    "common": "Franciaország"
                },
                "ita": {
                    "official": "Repubblica francese",
                    "common": "Francia"
                },
                "jpn": {
                    "official": "フランス共和国",
                    "common": "フランス"
                },
                "kor": {
                    "official": "프랑스 공화국",
                    "common": "프랑스"
                },
                "nld": {
                    "official": "Franse Republiek",
                    "common": "Frankrijk"
                },
                "per": {
                    "official": "جمهوری فرانسه",
                    "common": "فرانسه"
                },
                "pol": {
                    "official": "Republika Francuska",
                    "common": "Francja"
                },
                "por": {
                    "official": "República Francesa",
                    "common": "França"
                },
                "rus": {
                    "official": "Французская Республика",
                    "common": "Франция"
                },
                "slk": {
                    "official": "Francúzska republika",
                    "common": "Francúzsko"
                },
                "spa": {
                    "official": "República francés",
                    "common": "Francia"
                },
                "srp": {
                    "official": "Француска Република",
                    "common": "Француска"
                },
                "swe": {
                    "official": "Republiken Frankrike",
                    "common": "Frankrike"
                },
                "tur": {
                    "official": "Fransa Cumhuriyeti",
                    "common": "Fransa"
                },
                "urd": {
                    "official": "جمہوریہ فرانس",
                    "common": "فرانس"
                },
                "zho": {
                    "official": "法兰西共和国",
                    "common": "法国"
                }
            },
            "latlng": [
                46.0,
                2.0
            ],
            "landlocked": false,
            "borders": [
                "AND",
                "BEL",
                "DEU",
                "ITA",
                "LUX",
                "MCO",
                "ESP",
                "CHE"
            ],
            "area": 551695.0,
            "demonyms": {
                "eng": {
                    "f": "French",
                    "m": "French"
                },
                "fra": {
                    "f": "Française",
                    "m": "Français"
                }
            },
            "flag": "🇫🇷",
            "maps": {
                "googleMaps": "https://goo.gl/maps/g7QxxSFsWyTPKuzd7",
                "openStreetMaps": "https://www.openstreetmap.org/relation/1403916"
            },
            "population": 67391582,
            "gini": {
                "2018": 32.4
            },
            "fifa": "FRA",
            "car": {
                "signs": [
                    "F"
                ],
                "side": "right"
            },
            "timezones": [
                "UTC-10:00",
                "UTC-09:30",
                "UTC-09:00",
                "UTC-08:00",
                "UTC-04:00",
                "UTC-03:00",
                "UTC+01:00",
                "UTC+02:00",
                "UTC+03:00",
                "UTC+04:00",
                "UTC+05:00",
                "UTC+10:00",
                "UTC+11:00",
                "UTC+12:00"
            ],
            "continents": [
                "Europe"
            ],
            "flags": {
                "png": "https://flagcdn.com/w320/fr.png",
                "svg": "https://flagcdn.com/fr.svg",
                "alt": "The flag of France is composed of three equal vertical bands of blue, white and red."
            },
            "coatOfArms": {
                "png": "https://mainfacts.com/media/images/coats_of_arms/fr.png",
                "svg": "https://mainfacts.com/media/images/coats_of_arms/fr.svg"
            },
            "startOfWeek": "monday",
            "capitalInfo": {
                "latlng": [
                    48.87,
                    2.33
                ]
            },
            "postalCode": {
                "format": "#####",
                "regex": "^(\\d{5})$"
            }
        }
    ];

    constructor(private countriesService: CountriesService) { }

    ngOnInit(): void {
        if (this.countryToSelect) {
            this.selectedCountry = this.countries.find(
                (c) => c.cca2 === this.countryToSelect
            );
        } else {
            this.selectedCountry = this.countries.find((c) => c.cca2 === 'FR');
        }

        // this.countriesService.getCountryList().then((countryList) => {
        //     this.countries = countryList;
        // });
    }

    public change(e) {
        this.onChanged.emit({
            codeCountry: e.value.cca2,
            countryPhone: e.value.idd.root + e.value.idd.suffixes[0],
        });
    }
}
